<template>
	<div class="caseContent">
		<banner :picS="bannerS"></banner>
		<div class="widthBox">
			<div class="caseTitle">
				<titleOne id="case_1" en='Case sharing' title="ケーススタディー"></titleOne>
			</div>
			<div class="picBox">
				<bannerA v-if="mobile" :mobile="mobile" :slides="1" :scale="false" jsClass="case_banner" :autoPlay="false" :padding="50" :list="caseSharing" :navigation="true"></bannerA>
				<bannerA v-else :autoPlay="false" :padding="50" itemType="case" :list="caseSharing"></bannerA>
			</div>
		</div>
		<div class="list">
			<div class="listTitle">
				<titleOne id="case_2" en='More case' title="その他の事例"></titleOne>
			</div>
			<ul>
				<li v-for="item in cases">
					<div class="widthBox">
						<div class="pic">
							<video :poster="item.back_img" controls>
								<source :src="item.video" type="video/mp4">
								<source :src="item.video" type="video/ogg">
							</video>
						</div>
						<div class="cont">
							<h4>{{item.title}}</h4>
							<p>{{item.describe}}</p>
							<div class="info">{{item.content}}</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<el-pagination v-if="total>10" :page-size="pageSize" :current-page="page" @current-change="joinChange" layout="prev, pager, next" :total="total"></el-pagination>
		
	</div>
</template>

<script>
	import banner from '@/components/banner.vue'
	import bannerA from '@/components/bannerA.vue'
	import titleOne from '@/components/titleOne.vue'
	export default {
		name: 'case',
		components:{
			banner,titleOne,bannerA
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
		},
		data:function() {
			return {
				caseSharing:{},
				cases:[],
				total:0,
				page:1,
				pageSize:10,
				mobile:false
			};
		},
		created() {
			var width = document.body.clientWidth
			if(width <= 768){
				this.mobile = true
			}
			this.$api.get('home/index/caseSharing',{}).then(res=>{
				this.caseSharing = res.data
			})
			this.getData()
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods:{
			getData(){
				this.$api.post('home/index/moreCases',{
					page:this.page,
					pageSize:this.pageSize
				}).then(res=>{
					this.cases = res.data.more_cases
					this.total = res.data.total
				})
			},
			joinChange(e){
				this.page = e
				this.getData()
			}
		}
	}
</script>

<style lang="scss">
	$fontcolor:#1D2A55;
	.caseContent{
		.caseTitle{
			margin-top:60px;
		}
		.picBox{
			padding:50px 0 80px;
		}
		.el-pagination{
			margin:50px auto 0;
			text-align: center;
			button{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-weight: 500;
				background-color: rgba($color: #1D2A55, $alpha:1);
				color: #FFF;
				i{
					font-size:24px;
				}
				&:disabled {
				    color: #1D2A55;
				    background-color: rgba($color: #1D2A55, $alpha:0.1);
				    cursor: not-allowed;
				}
			}
			li{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-size:24px;
				margin:0 5px;
				font-weight: 500;
				color:rgba($color: #1D2A55, $alpha: 0.4);
				&.active{
					color:rgba($color: #1D2A55, $alpha: 1);
				}
			}
		}
		
		.list{
			border-top: 1px solid $fontcolor;
			margin-top: 80px;
			padding-top: 80px;
			li{
				position: relative;
				padding:50px 0;
				&::after{
					content:'';display: block;
					width:100%;
					height:40%;
					background:rgba($color:$fontcolor, $alpha: 0.05);
					position: absolute;
					left:0;
					bottom:0;
					z-index: 1;
				}
				// &::after{
				// 	content:'';display: block;width:0;height:0;clear: both;overflow: hidden;
				// }
				.pic{
					width:680px;
					float:left;
					position: relative;
					z-index: 2;
					img{width:100%;}
					video{
						width:100%;
						height:400px;
						background:#000;
					}
				}
				
				.cont{
					margin-left:750px;
					padding-top:80px;
					h4{
						font-size:30px;
						color:$fontcolor;
					}
					p{
						font-size:24px;
						color:$fontcolor;
						margin-top:20px;
					}
					.info{
						font-size:18px;
						line-height:32px;
						color:$fontcolor;
						margin-top:100px;
					}
				}
			}
		}
	}
	
	@media screen and(max-width:1400px) {
		.caseContent{
			.list{
				li{
					.pic{
						width:500px;
						video{
							height:350px;
						}
					}
					.cont{
						margin-left:550px;
					}
				}
			}
		}
	}
	@media screen and(max-width:1200px) {
		.caseContent{
			.list{
				li{
					&::before{
						height:50%;
					}
					.pic{
						width:450px;
						video{
							height:350px;
						}
					}
					.cont{
						padding-top:50px;
						margin-left:500px;
					}
				}
			}
		}
	}
	@media screen and(max-width:768px) {
		.caseContent{
			.caseTitle{
				margin-top:20px;
			}
			.picBox{
				padding:20px 0 40px;
			}
			.list{
				margin-top:10px;
				padding-top:20px;
				li{
					margin:20px;
					padding:0px 0;
					.widthBox{
						padding:0;
					}
					&::after  {
					    height: 100%;
						background: none;
					}
					.pic{
						width:100%;
						float:none;
						video{
							height:240px;
						}
					}
					.cont{
						// padding:10px 20px;
						padding-top: 20px;
						margin-left:0;
						h4{
							font-size:16px;
							line-height:24px;
							color: #272E4D;
						}
						p{
							font-size:12px;
							line-height:24px;
							margin-top:0;
							color: #3E404D;
						}
						.info{
							margin-top:10px;
							font-size:14px;
							line-height:20px;
							color: #8A8D99;
						}
					}
				}
			}
		}
	}
</style>
